<template>
    <div class="lvya-news">
        <div style="height:150px;"></div>
        <div class="news-tab">
            <ul class="tab-tilte">
                <li @click="cur=0" :class="{active:cur==0}">教育资讯</li>
                <li @click="cur=1" :class="{active:cur==1}">行业动态</li>
            </ul>
            <div class="tab-content" style="background:#fff;">
                <div v-show="cur==0">
                    <ul class="tab-content-ul">
                        <li v-for="(item,i) in listData" :key="i" @click="hrefUrl(item.id)">
                            <img :src="'http://www.lvya.org/'+item.img" alt="" class="li-img">
                            <div>
                                <p class="newsli-p1">{{item.title}}</p>
                                <p class="newsli-p2">
                                    {{item.remark}}
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="pagination">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            background
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageNum"
                            layout="total, prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
                <div v-show="cur==1">
                    <ul class="tab-content-ul">
                        <li v-for="(item,i) in listData2" :key="i" @click="hrefUrl2(item.id)">
                            <img :src="'http://www.lvya.org/'+item.img" alt="" class="li-img">
                            <div>
                                <p class="newsli-p1">{{item.title}}</p>
                                <p class="newsli-p2">
                                    {{item.remark}}
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="pagination2">
                        <el-pagination
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                            background
                            :current-page="currentPage2"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="pageNum2"
                            layout="total, prev, pager, next"
                            :total="total2">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
export default {
    components: {
    },
    data() {
        return {
            // cur: 0,
            cur: this.$route.query.cur ? this.$route.query.cur : '0',
            listData: [],
            currentPage: 1, // 默认在第一页
            pageNum: 10, // 默认每页显示1条数据
            total: 0, // 默认总条数为一条
            listData2: [],
            currentPage2: 1, // 默认在第一页
            pageNum2: 10, // 默认每页显示1条数据
            total2: 0, // 默认总条数为一条
        }
    },
    methods: {
        getList() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            console.log(origin, '1111')
            axios.post(`${origin}/api/news/0/list`, qs.stringify({
                pageNo: this.currentPage,
            }))
            .then(res => {
                this.listData = res.data.resultList
                this.total = res.data.rowCount
            })
        },
        handleCurrentChange: function(currentPage) {
            this.currentPage = currentPage
            this.getList()
        },
        handleSizeChange(val) {
            this.pageNum = val
            this.getList()
        },
        // 行业动态
        getList2() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            console.log(origin, '1111')
            axios.post(`${origin}/api/news/1/list`, qs.stringify({
                pageNo: this.currentPage2,
            }))
            .then(res => {
                this.listData2 = res.data.resultList
                this.total2 = res.data.rowCount
            })
        },
        handleCurrentChange2: function(currentPage2) {
            this.currentPage2 = currentPage2
            this.getList2()
        },
        handleSizeChange2(val2) {
            this.pageNum2 = val2
            this.getList2()
        },
        hrefUrl(id, cur) {
            this.$router.push({ path: '/newsDetails', query: { id, cur: 0 }})
        },
        hrefUrl2(id, cur) {
            this.$router.push({ path: '/newsDetails', query: { id, cur: 1 }})
        },
    },
    created() {
        this.getList()
        this.getList2()
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
/* @import url("./less/style.less"); */
.lvya-news{
    width: 100%;
    height: 100%;
    background: url('../../../../public/static/lvya/news/banner5.png') no-repeat center top;
    background-size: 100% auto;
    .news-tab{
        width: 1200px;
        margin: 0px auto;
        border-radius: 5px;
        padding: 30px 0;
    }
    .news-tab .tab-tilte{
        width: 260px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
    }
    .news-tab .tab-tilte li{
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
    }
    /* 点击对应的标题添加对应的背景颜色 */
    .news-tab .tab-tilte .active{
        border-bottom: 2px solid #05b21a;
        box-sizing: border-box;
    }
    .news-tab .tab-content div{
        text-align: left;
    }
    .tab-content-ul{
        width: 100%;
        border: 1px solid #eee;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding-inline-start: 0px;
        margin: 20px 0;
    }
    .tab-content-ul li{
        display: flex;
        border-bottom: 1px solid #eee;
        padding: 20px 20px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .li-img{
        width: 185px;
        height: 120px;
        margin-right: 15px;
    }
    .newsli-p1{
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
    }
    .newsli-p2{
        font-size: 14px;
        color: #666;
        line-height: 25px;
        margin-top: 10px;
    }
}
</style>
